<template>
  <div class="w-full">
    <DropdownList @on-item-selected="dropdownSelection = $event" @on-item-reset="dropdownSelection = {}" />

    <!-- The selected country name will be visible below, when selected -->
<!--    {{ dropdownSelection.name }}-->

  </div>
</template>

<script>
import DropdownList from '@/components/DropdownList.vue'
export default {
  name:"DropdownListSearch",
  data () {
    return {
      dropdownSelection: {}
    }
  },
  components: {
    DropdownList
  }
}
</script>